<template>
    <section class="brandManage">
        <h2 class="title-page">品牌管理</h2>
        <div class="content-brand" ref="container">
            <div class="brand-flex brand-info">
                <el-input placeholder="请输入品牌名" v-model="name" class="name-input">
                    <el-button slot="append" icon="el-icon-search" @click="getBrandList"></el-button>
                </el-input>
                <el-button type="primary" icon="el-icon-plus" @click="addClick()">新增</el-button>
            </div>
            <el-table  :max-height="tableHeight" :data="tableData" style="width: 100%"
                      :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
                <el-table-column prop="id" label="品牌ID" width="80"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="name" label="品牌名"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="corp_id" label="企微公司ID"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="agent_id" label="agentId"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="corp_secret" label="企微密钥" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="developer_id" label="道一ID"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="created_at" label="新增时间"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="updated_at" label="编辑时间"  :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="操作" width="160px">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editBrand(scope.row.id)">编辑</el-button>
                        <el-button type="text" @click="showCompany(scope.row.id)">门店管理</el-button>
                        <el-button type="text" @click="editBrandStatus(scope.row.id,scope.row.is_enable === 0 ? 1 : 0)">
                            {{ scope.row.is_enable === 0 ? '启用' : '停用' }}
                        </el-button>
                        <el-button type="text" @click="createQW(scope.row.corp_id)">创建企微菜单</el-button>
                        <el-button type="text" @click="callYZ(scope.row.corp_id)">企微验证</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 新增品牌 -->
        <el-dialog :title="form.id?'编辑品牌':'新增品牌'" :visible.sync="addDialog" width="680px" :before-close="closeForm">
            <el-form ref="form" :rules="rules" :model="form" label-width="110px">
                <el-form-item label="品牌名" prop="name">
                    <el-input v-model="form.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="企微ID" prop="corp_id">
                    <el-input v-model="form.corp_id" clearable></el-input>
                </el-form-item>
                <el-form-item label="agentId" prop="agent_id">
                    <el-input v-model="form.agent_id" clearable></el-input>
                </el-form-item>
                <el-form-item label="企微密钥" prop="corp_secret">
                    <el-input v-model="form.corp_secret" clearable></el-input>
                </el-form-item>
                <el-form-item label="企微应用密钥" prop="corp_application_secret">
                    <el-input v-model="form.corp_application_secret" clearable></el-input>
                </el-form-item>
                <el-form-item label="道一开发者ID">
                    <el-input v-model="form.developer_id" clearable></el-input>
                </el-form-item>
                <el-form-item label="道一凭证密钥">
                    <el-input v-model="form.developer_key" clearable></el-input>
                </el-form-item>
                <el-form-item label="道一平台id">
                    <el-input v-model="form.platform_id" clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="closeForm" class="close-btn">取 消</el-button>
                <el-button type="primary" @click="addInfo(form.id)">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 门店管理 -->
        <el-dialog :visible.sync="companyDialog" width="900px" :show-close="false">
            <div class="brand-flex company-title">
                <span slot="title" class="titles">门店管理</span>
                <el-button type="primary" @click="toAddCompany()">新增</el-button>
            </div>
            <el-table :data="companyList" style="width: 100%"
                      :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
                <el-table-column prop="corp_store_name" label="企微门店"></el-table-column>
                <el-table-column prop="alias_store_name" label="门店别名"></el-table-column>
                <el-table-column prop="cs_store_id" label="财升门店ID">
                    <template  slot-scope="scope">
                        {{ scope.row.cs_store_id != 0 ?  scope.row.cs_store_id  : ''  }}
                    </template>
                </el-table-column>
                <el-table-column prop="cs_store_id" label="是否启用道一考勤">
                    <template  slot-scope="scope">
                        {{ scope.row.is_do_check == 0 ? '否' :'是' }}
                    </template>
                </el-table-column>
                <el-table-column prop="premises_id" label="狗号"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toCompanyEdit(scope.row.id)">编辑</el-button>
                        <el-button type="text" @click="toDel(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 编辑门店 -->
            <el-dialog width="700px" title="编辑门店" :visible.sync="editCompany" append-to-body>
                <el-form :model="companyInfo" label-width="130px">
                    <el-form-item label="企微门店" prop="name">
                        <span>{{ companyInfo.corp_store_name }}</span>
                    </el-form-item>
                    <el-form-item label="门店别名" prop="name">
                        <el-input v-model="companyInfo.alias_store_name" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="财升门店ID" prop="name">
                        <el-input v-model="companyInfo.cs_store_id" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="是否启用道一考勤" prop="name">
                         <el-select v-model="companyInfo.is_do_check" placeholder="请选择" style="width:100%">
                            <el-option
                                v-for="item in daoLisrt"
                                   :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="狗号" prop="name">
                        <el-input v-model="companyInfo.premises_id" clearable></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button @click="editCompany=false" class="close-btn">取 消</el-button>
                    <el-button type="primary" @click="saveCompanyEdit(companyInfo.id)">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 新增门店 -->
            <el-dialog width="1300px" title="新增门店" :visible.sync="addCompany" append-to-body :before-close="closeAddCom">
                <div class="company-add">
                    <div class="checkbox-groups">
                        <p class="">部门</p>
                        <!--                        <el-checkbox v-for="item in allCompany" :label="item" :key="item.id">{{ item.name }}
                                                </el-checkbox>-->
                        <el-tree
                            ref='staffTree'
                            :data="allCompany"
                            show-checkbox
                            node-key="id"
                            :default-expanded-keys="[]"
                            :default-checked-keys="[]"
                            :props="defaultProps"
                            :check-strictly="true"
                            @check="treeCheck">
                        </el-tree>
                    </div>
                    <div class="right-company">
                        <div class="company-tab-title">
                            <span>已选部门</span>
                            <span>门店名</span>
                            <span>财升门店ID</span>
                            <span>是否开启道一考勤</span>
                            <span>场所狗号</span>
                        </div>
                        <div class="company-tab-info" v-for="item in checkCompanyList" :key="item.id">
                            <span>{{ item.corp_store_name }}</span>
                            <el-input v-model="item.alias_store_name" placeholder="请输入门店名"></el-input>
                            <el-input v-model="item.cs_store_id" placeholder="不用财升id则不填"></el-input>
                            <el-select v-model="item.is_do_check" placeholder="请选择">
                                <el-option
                                    v-for="item in daoLisrt"
                                    :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-input v-model="item.premises_id" placeholder="请输入场所狗号"></el-input>
                        </div>
                    </div>
                </div>
                <div slot="footer">
                    <el-button @click="closeAddCom" class="close-btn">取 消</el-button>
                    <el-button type="primary" @click="toSaveAddCompany()">确 定</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </section>
</template>
<script>
import {
    addBrand,
    brandInfo,
    brandList,
    companyInfo,
    companyList, delCompany, getAddCompanyInfo, saveAddCompany,
    saveBrandStatus,
    saveEditBrand, saveEditCompany, createMenu, updateCallback
} from '../../../api/srv-store'
import vue from "vue";

export default {
    name: 'brandManage',
    data() {
        return {
            tableHeight:0,
            name: '',
            tableData: [],
            addDialog: false,
            form: {},
            rules: {
                name: [
                    {required: true, message: '请输入品牌名称', trigger: 'blur'},
                    {max:20, message: '最多20个字符', trigger: 'blur'}
                ],
                corp_id: [
                    {required: true, message: '请输入企微ID', trigger: 'blur'}
                ],
                agent_id: [
                    {required: true, message: '请输入agentId', trigger: 'blur'}
                ],
                corp_secret: [
                    {required: true, message: '请输入企微密匙', trigger: 'blur'}
                ],
                corp_application_secret: [
                    {required: true, message: '请输入企微应用密匙', trigger: 'blur'}
                ],
            },
            companyDialog: false,
            companyList: [],
            editCompany: false, //编辑门店
            companyInfo: {},
            addCompany: false,
            allCompany: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                disabled: 'is_true'
            },
            checkCompanyList: [],
            brandId: 0,//添加门店时的品牌id
            daoLisrt:[
                {
                    value:0,
                    name:'否'
                },
                {
                    value:1,
                    name:'是'
                }
            ]
        }
    },
    created() {
        this.getBrandList();
        this.calHeight()
    },
    methods: {
        createQW: function(cid){
            this.$confirm('是否创建企微菜单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '创建中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                createMenu({
                    corp_id: cid
                }).then(() => {
                    loading.close();
                    this.$message({
                        type: 'success',
                        message: '创建成功'
                    });
                }).catch(err => {
                    loading.close();
                    console.log(err);
                })
            }).catch(() => {
               
            });
        },
        callYZ: function(cid){
            this.$confirm('是否验证企微?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '验证中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updateCallback({
                    corp_id: cid
                }).then(() => {
                    loading.close();
                    this.$message({
                        type: 'success',
                        message: '验证成功'
                    });
                }).catch(err => {
                    loading.close();
                    console.log(err);
                })
            }).catch(() => {
               
            });
        },
         calHeight () {
            this.$nextTick(() => {
                const rect = this.$refs.container.getBoundingClientRect()
                this.tableHeight = rect.height - 170
            })
        },
        //品牌列表
        getBrandList: function () {
            brandList({
                keyword: this.name,
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.tableData = Er.data
                }
            })
        },

        //品牌新增弹窗处理
        addClick() {
            this.addDialog = true;
            this.form = {};
            // if (this.$refs.form) {
            //     this.$refs.form.resetFields();
            // }
        },

        //编辑品牌弹窗
        editBrand(id) {
            console.log(id)
            //获取品牌详情
            brandInfo({
                brand_id: id,
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.form = Er.data
                    this.addDialog = true
                }
            })
        },

        //新增/编辑品牌
        addInfo(id) {
            let data = {
                name: this.form.name,
                corp_id: this.form.corp_id,
                agent_id: this.form.agent_id,
                corp_secret: this.form.corp_secret,
                corp_application_secret: this.form.corp_application_secret,
                developer_id: this.form.developer_id,
                developer_key: this.form.developer_key,
                platform_id: this.form.platform_id,
            }
            this.$refs.form.validate((valid) => {//参数校验
                if (valid) {
                    console.log('----------1')
                    if (id) {//编辑
                        if(!this.form.name.trim() || !this.form.corp_id.trim() || !this.form.agent_id || !this.form.corp_secret.trim() || !this.form.corp_application_secret.trim() || !this.form.developer_id.trim() || !this.form.developer_key.trim() || !this.form.platform_id.trim()){
                            this.$message('输入不能为空')
                            return false;
                        }
                        vue.set(data, 'brand_id', id)
                        saveEditBrand(data).then(Er => {
                            if (Er.return_code === 200) {
                                this.addDialog = false;
                                this.$message({
                                    message: Er.return_msg,
                                    type: 'success'
                                });
                                this.getBrandList()
                            }
                        })
                    } else {//新增
                        if(!this.form.name.trim() || !this.form.corp_id.trim() || !this.form.agent_id || !this.form.corp_secret.trim() || !this.form.corp_application_secret.trim() || !this.form.developer_id.trim() || !this.form.developer_key.trim() || !this.form.platform_id.trim()){
                            this.$message('输入不能为空')
                            return false;
                        }
                        for(let i=0,ii=this.tableData.length;i<ii;i++){
                            if(this.tableData[i].name == this.form.name){
                                this.$message('品牌名称不能重复')
                                return false;
                            }
                        }
                        
                        addBrand(data).then(Er => {
                            if (Er.return_code === 200) {
                                this.addDialog = false;
                                this.$message({
                                    message: Er.return_msg,
                                    type: 'success'
                                });
                                this.getBrandList()
                            }
                        })
                    }
                } else {
                    console.log('----------2')
                    return false;
                }
            });
        },

        //编辑品牌启用状态
        editBrandStatus(id, is_enable) {
            saveBrandStatus({
                brand_id: id,
                is_enable: is_enable
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.$message({
                        message: Er.return_msg,
                        type: 'success'
                    });
                    this.getBrandList()
                }
            })
        },

        //门店列表
        showCompany: function (id) {
            companyList({
                brand_id: localStorage.getItem('brand_id') != 0 ? parseInt(localStorage.getItem('brand_id')) : id,
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.companyList = Er.data
                    this.brandId = localStorage.getItem('brand_id') != 0 ? parseInt(localStorage.getItem('brand_id')) : id,
                        this.companyDialog = true;
                }
            })
        },

        //新增门店
        toAddCompany() {
            //获取添加门店时需要的企微相关信息
            getAddCompanyInfo({
                brand_id: this.brandId,
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.allCompany = Er.data.qw_department
                    this.addCompany = true
                }
            })
        },

        //树状节点选择事件
        treeCheck: function (nowNode, checkNode) {
            let arr = checkNode.checkedNodes, newarr = [];
            arr.map(item => {
                item.brand_id = this.brandId
                item.corp_store_id = item.id
                item.corp_store_name = item.name
                newarr.push(item)
            })
            this.checkCompanyList = newarr;
        },

        //保存新增门店信息
        toSaveAddCompany() {
            let newarr = [];
            this.checkCompanyList.map(item => {
                let new_obj = {};
                new_obj.brand_id = this.brandId
                new_obj.corp_store_id = item.id
                new_obj.corp_store_name = item.name
                new_obj.alias_store_name = item.alias_store_name
                new_obj.cs_store_id = item.cs_store_id
                new_obj.premises_id = item.premises_id
                new_obj.is_do_check = item.is_do_check
                newarr.push(new_obj)
            })
            saveAddCompany({
                store_list: JSON.stringify(newarr)
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.allCompany = Er.data.qw_department
                    this.$message({
                        message: Er.return_msg,
                        type: 'success'
                    });
                    this.addCompany = false
                    this.showCompany(this.brandId)
                }
            })
        },

        //编辑门店
        toCompanyEdit: function (id) {
            companyInfo({
                store_id: id,
            }).then(Er => {
                if (Er.return_code === 200) {
                    Er.data.cs_store_id = Er.data.cs_store_id != 0 ? Er.data.cs_store_id : ''
                    this.companyInfo = Er.data
                    this.editCompany = true;
                }
            })
        },

        //保存门店编辑信息
        saveCompanyEdit: function (id) {
            saveEditCompany({
                store_id: id,
                alias_store_name: this.companyInfo.alias_store_name,
                cs_store_id: this.companyInfo.cs_store_id,
                premises_id: this.companyInfo.premises_id,
                is_do_check:this.companyInfo.is_do_check
                
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.editCompany = false;
                    this.$message({
                        message: Er.return_msg,
                        type: 'success'
                    });
                    this.showCompany(this.brandId)
                }
            })
        },

        //删除门店
        toDel: function (id) {
            this.$confirm('是否确认删除该门店', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delCompany({
                    store_id: id,
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            message: Er.return_msg,
                            type: 'success'
                        });
                        this.showCompany(this.brandId)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        //关闭弹窗
        closeForm: function(){
            this.addDialog = false;
            this.form = {};
        },
        //关闭门店新增弹窗
        closeAddCom: function(){
            this.addCompany = false;
            this.checkCompanyList = [];
        }
    }
}
</script>
<style scoped>
.brandManage {
    min-height: 100%;
    background-color: #f7fafb;
}

.title-page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
}

.content-brand {
    height: calc(100vh - 195px);
    padding: 0px 40px 40px 40px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
    
}

.brand-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name-input {
    width: 380px;
}

.brand-info {
    padding: 40px 0;
}

.brand-info /deep/ .el-input-group__append {
    border-left: 1px solid #ffffff;
    background-color: #ffffff;
    margin-left: -1px;
}

.el-form /deep/ .el-form-item__label {
    text-align: left;
}

.close-btn {
    background-color: rgba(62, 86, 110, 0.1);
    border-color: rgba(62, 86, 110, 0.1);
}

.brandManage /deep/ .el-dialog__footer {
    text-align: center;
}

.brandManage /deep/ .company-title {
    margin-top: -10px;
    margin-bottom: 20px;
}

.titles {
    font-size: 20px;
    color: #3E566E;
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
}

.company-add {
    display: flex;
    height: 460px;
    border-radius: 6px;
    border: 1px solid rgba(62, 86, 110, 0.2);
}

.checkbox-groups {
    min-width: 225px;
    height: (100% -20px);
    padding-left: 20px;
    padding-top: 20px;
    border-right: 1px solid rgba(62, 86, 110, 0.1);
    overflow: auto;
}

.checkbox-groups /deep/ label {
    display: block;
}

.right-company {
    flex: 1;
    height: 100%;
    margin: 0 20px;
    overflow-y: auto;
    text-align: center;
}

.company-tab-title, .company-tab-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid rgba(62, 86, 110, 0.09);
}

.company-tab-title {
    color: rgba(62, 86, 110, 0.6);
}

.company-tab-info {
    color: #3E566E;
}

.company-tab-info /deep/ input {
    text-align: center;
}

.company-tab-title span, .company-tab-info div, .company-tab-info span {
    width: 30%;
}
</style>